/**
 * 封装网络请求，并导出get,post方法，导出的方法会在mainimport.js中封装成全局方法，方便使用
 */
import axios from "axios"
import { Toast } from "vant"
// process.env.VUE_APP_BASE_API根据环境不同引入不同api地址

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base api url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    // 不传递默认开启loading
    if (!config.hideloading) {
      // loading
      Toast.loading({
        forbidClick: true
      })
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)
/**
 * respone拦截器，
 * 这里后期还需要改一下：当res.errCode !== 0时，需要做一个提示，但不需要拦截请求。
 */ 
service.interceptors.response.use(
  (response) => {
    Toast.clear()
    const res = response.data
    if (res.errCode && res.errCode !== 0) {
      Toast(res.errMsg);
    }  
      return Promise.resolve(res)
    // }
  },
  (error) => {
    Toast.clear()
    return Promise.reject(error)
  }
)

/**
 *  @auther yuyuanda
 *  在原有request基础上封装post网络请求，使用方法如下：
 *  myUrl：必填地址
 *  params：data必填参数
 *  params：选填参数
 * this.$post('/adminapi/v1/video/vod/category',{"name":'yyd',"age":12},{"name":'yyd',"age":12}).then((response) => {
       console.log(response)
    })
 */
export function post(myUrl, data = {}, params = {}) {
  return service({
    headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    url: myUrl,
    method: 'post',
    params,
    data
  })
}

/**
 *  @auther yuyuanda
 *  在原有request基础上封装post网络请求，使用方法如下：
 *  myUrl：必填地址
 *  params：选填
 * this.$get('/adminapi/v1/video/vod/category',{"name":'yyd',"age":12}).then((response) => {
       console.log(response)
    })
 */
export function get(myUrl, params = {},hideloading=false) {
  return service({
    url: myUrl,
    method: 'get',
    params,
    hideloading
  })
}

/**
 * 用于封装网络返回成功时的提示
 */
export function respTip(errCode, tipStr = '操作成功') {
  if (errCode == 0) {
    Toast.success(tipStr);
    return true
  }
  return false
}
