/**
 *  路由文件
 * keepAlive: true 代表需要做页面缓存
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/previousservice',
    name: 'previousservice',
    component: () => import('../views/previousservice'),
    meta: { title: '往届服务', keepAlive: true }
  },
  {
    path: '/previousservice/addservice',
    name: 'addpreviousservice',
    component: () => import('../views/previousservice/addservice.vue'),
    meta: { title: '添加服务' }
  }
]
const router = new VueRouter({
  routes
})
export default router
